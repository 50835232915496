import axios, { AxiosAdapter } from 'axios'
import { throttleAdapterEnhancer } from 'axios-extensions'
import store from '../store'

const instance = axios.create({
  // baseURL: 'https://api.poiskpl.ru/api',
  baseURL: process.env.VUE_APP_BACKEND_API,
  // withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})
instance.interceptors.request.use(function (config) {
  // @ts-ignore
  config.headers.Authorization = 'Bearer ' + store.state.auth.token
  return config
})

export const Http = instance

const instance2 = axios.create({
  baseURL: 'http://188.225.11.172:8000/api',
  // baseURL: process.env.VUE_APP_BACKEND_API,
  // withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    threshold: 2000
  })
})

instance2.interceptors.request.use(function (config) {
  // @ts-ignore
  config.headers.Authorization = 'Bearer ' + store.state.auth.token
  return config
})

export const ThrottleHttp = instance2
