<template>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <g
      clip-path="url(#clip0)"
      stroke="#F35555"
    >
      <path
        d="M22.792 10.454h0a2.243 2.243 0 011.308 2.048v0H24h.1l-1.308-2.048zm0 0l-1.9-.868m1.9.868l-1.9-.868m0 0l-1.686-2.69a2.78 2.78 0 00-2.372-1.306H9.585c-.872 0-1.698.403-2.228 1.097h0l-2.178 2.85-3.632.995h0A2.249 2.249 0 00-.1 12.699V14.1c0 1.239.987 2.264 2.23 2.264h.238a2.83 2.83 0 003.43 1.954H5.8a2.83 2.83 0 002.009-1.954h8.11l4.974-6.778zm.465 6.778a3.07 3.07 0 00.02-.075l-.097-.025v.1h.077zM6.73 15.571h0a1.646 1.646 0 11-3.292 0 1.646 1.646 0 013.292 0zm13.55 0h0a1.646 1.646 0 11-3.293 0 1.646 1.646 0 013.292 0zM1.078 12.699v-.001c-.005-.48.314-.899.777-1.03 0 0 0 0 0 0l3.831-1.047h.001a.58.58 0 00.314-.213l2.29-3.004s0 0 0 0c.31-.398.788-.63 1.293-.63h7.25a1.594 1.594 0 011.37.75h0l1.78 2.841c.061.104.156.18.254.226h.001l2.056.94s0 0 0 0c.378.175.62.553.615.97V14.2h0c-.047.546-.489.98-1.04.98h-.433a2.828 2.828 0 00-3.114-2.414 2.826 2.826 0 00-2.493 2.414H7.888a2.828 2.828 0 00-3.114-2.414 2.825 2.825 0 00-2.492 2.414h-.157c-.586 0-1.046-.488-1.046-1.08v-1.402z"
        fill="F35555"
        stroke-width=".2"
      />
      <path
        d="M21 4l-6 6M15 4l6 6"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="transparent" d="M0 0h24v24H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'NoCarIcon'
}
</script>
