<template>
  <svg
    class="custom-icon"
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)"><path d="M21 22.452h-1.548V3.484a.774.774 0 00-.775-.774h-5.806V.774A.774.774 0 0012.097 0H6.726a.774.774 0 00-.774.774V2.71h-.63a.774.774 0 00-.774.774v18.968H3A.774.774 0 003 24h18a.774.774 0 100-1.548zM7.5 1.548h3.823V2.71H7.5V1.548zm2.952 20.904v-3.29h3.096v3.29h-3.096zm4.645 0v-3.29h.338a.774.774 0 100-1.55h-6.87a.774.774 0 000 1.55h.338v3.29H6.097V4.258h11.806v18.194h-2.806zm-4.21-15.92a.774.774 0 01-.774.774h-.871a.774.774 0 010-1.548h.87c.428 0 .775.347.775.774zm4.597 0a.774.774 0 01-.774.774h-.871a.774.774 0 010-1.548h.87c.428 0 .775.347.775.774zm-4.597 2.903a.774.774 0 01-.774.775h-.871a.774.774 0 010-1.549h.87c.428 0 .775.347.775.774zm4.597 0a.774.774 0 01-.774.775h-.871a.774.774 0 010-1.549h.87c.428 0 .775.347.775.774zm-4.597 2.904a.774.774 0 01-.774.774h-.871a.774.774 0 010-1.548h.87c.428 0 .775.346.775.774zm4.597 0a.774.774 0 01-.774.774h-.871a.774.774 0 110-1.548h.87c.428 0 .775.346.775.774zm-4.597 2.903a.774.774 0 01-.774.774h-.871a.774.774 0 010-1.548h.87c.428 0 .775.346.775.774zm4.597 0a.774.774 0 01-.774.774h-.871a.774.774 0 110-1.548h.87c.428 0 .775.346.775.774z" fill="#currentColor"/></g>
    <defs>
      <clipPath id="clip0"><path fill="#none" d="M0 0h24v24H0z"/></clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CompanyIcon'
}
</script>
