
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
// import { Http } from '@/apis/Http'
import { User } from '@/apis/User'
// import _ from 'lodash'

@Component({
  name: 'TheSidebar'
})

export default class TheSidebar extends Vue {
  @Prop(Boolean) fixedNavigationDrawer!: boolean
  @PropSync('showMenu', { type: Boolean }) syncShowMenu!: boolean

  menu = []
  mini = false
  userRole = ''

  get isExpandMenu () {
    return !this.fixedNavigationDrawer && this.$vuetify.breakpoint.width >= 600
  }

  get isPermanentMenu () {
    return this.fixedNavigationDrawer && this.$vuetify.breakpoint.width >= 600
  }

  created() {
    this.userRole = this.$store.getters['auth/userRole']
    this.getMenu()
    if (this.userRole === 'admin') {
      this.mini = true
    }
    this.$store.dispatch('getNotViewed')

    // @ts-ignore
    this.$socket.on('new_message', (data) => this.setNotViewed(data))
  }

  setNotViewed(data) {
    if (data?.id) this.$store.commit('setNotViewed', data.id)
    // console.log(data)
  }

  getMenu() {
    User.getMenu()
      .then(response => {
        this.menu = response.data
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Упс!')
      })
  }

  getLink(link) {
    return link === 'telegramLink' ? '' : link
  }

  getHref(link) {
    return link === 'telegramLink' ? this.$store.state.auth.user.tg_link : ''
  }

  async logout() {
    // const userRole = this.$store.getters['auth/userRole']
    await this.$store.dispatch('auth/signOut')

    this.$router.push({ name: 'ChooseRole' })
  }
}
