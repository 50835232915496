<template>
  <v-dialog
    v-model="showWelcomeModal"
    overlay-opacity="0.8"
    max-width="640"
    scrollable
  >
    <v-card class="pb-10">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="text-center" style="font-size: 18px">
        Новые возможности на сервисе PoiskPL
      </v-card-subtitle>
      <v-card-text>
        <v-row class="mt-8">
          <v-col cols="12" sm="6" class="text-center">
            <img src="@/assets/icons/welcome_1.svg" alt="plus-icon">
            <p>Единая заявка для запроса КП на лизинг в лизинговых компаниях</p>
          </v-col>
          <v-col cols="12" sm="6" class="text-center">
            <img src="@/assets/icons/welcome_2.svg" alt="plus-icon">
            <p>Получайте коммерческие предложения от лизинговых компаний</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-center">
            <img src="@/assets/icons/welcome_3.svg" alt="plus-icon">
            <p>Сравнивайте предложения в одном окне</p>
          </v-col>
          <v-col cols="12" sm="6" class="text-center">
            <img src="@/assets/icons/welcome_4.svg" alt="plus-icon">
            <p>Получайте сразу информацию о вероятности одобрения</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  import { Http } from '@/apis/Http'

  export default {
    props: ['showWelcomeModal']
  }
</script>
