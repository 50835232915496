<template>
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.24 3.76C16.4 1.91 13.65 1.53 11.42 2.59L10.46 1.63C10.07 1.24 9.44 1.24 9.05 1.63L8.97 1.72C8.83 0.75 8 0 7 0C5.9 0 5 0.9 5 2C5 2.09 5 2.18 5.03 2.26C4.42 2.61 4 3.25 4 4C4 4.46 4.16 4.87 4.42 5.21C4.17 5.39 4 5.67 4 6V7.35C1.67 8.17 0 10.39 0 13C0 16.31 2.69 19 6 19C9.31 19 12 16.31 12 13C12 10.39 10.33 8.17 8 7.35V6C8 5.67 7.83 5.39 7.58 5.21C7.84 4.87 8 4.46 8 4C8 3.91 8 3.82 7.97 3.74C8.15 3.64 8.3 3.5 8.43 3.38C9.36 3.88 10 4.86 10 6V6.08C10.13 6.15 10.25 6.25 10.38 6.33C10.57 5.91 10.83 5.5 11.17 5.17C12.73 3.61 15.27 3.61 16.83 5.17C18.39 6.73 18.39 9.27 16.83 10.83C16.03 11.63 15 12 13.93 12C14 12.32 14 12.66 14 13C14 13.34 13.97 13.67 13.93 14C15.5 14 17.05 13.43 18.24 12.24C20.59 9.9 20.59 6.1 18.24 3.76ZM7 1C7.55 1 8 1.45 8 2C8 2.33 7.83 2.61 7.58 2.79C7.22 2.31 6.65 2 6 2C6 1.45 6.45 1 7 1ZM5.42 3.21C5.78 3.69 6.35 4 7 4C7 4.55 6.55 5 6 5C5.45 5 5 4.55 5 4C5 3.67 5.17 3.39 5.42 3.21ZM10 13C10 15.21 8.21 17 6 17C3.79 17 2 15.21 2 13C2 10.79 3.79 9 6 9C8.21 9 10 10.79 10 13Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'adminSupport'
}
</script>
