<template>
  <svg class="custom-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.506 6.025H9.741a.703.703 0 00-.704.703v5.322h-.803a.703.703 0 000 1.406h.803v1.607h-.803a.703.703 0 000 1.406h.803v1.556a.703.703 0 001.407 0v-1.556h3.062a.703.703 0 000-1.407h-3.062v-1.606h3.062a3.72 3.72 0 003.716-3.715c0-2.1-1.71-3.716-3.716-3.716zm0 6.025h-3.062V7.431h3.062c1.266 0 2.31 1.021 2.31 2.31a2.312 2.312 0 01-2.31 2.309z" fill="currentColor"/>
    <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 22.594C6.159 22.594 1.406 17.84 1.406 12 1.406 6.159 6.16 1.406 12 1.406c5.841 0 10.594 4.753 10.594 10.594 0 5.841-4.753 10.594-10.594 10.594z" fill="currentColor"/>
  </svg>
</template>

<script>

export default {
  name: 'RubleIcon'
}
</script>
