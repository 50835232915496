export default {
  namespaced: true,

  state: {
    loading: false,
    searchAdminPartners: '',
    searchAdminBrands: '',
    searchAdminUsers: '',
    searchAdminRequests: '',
    searchAdminModerations: '',
    searchLcDealerships: '',
    searchLcRequests: '',
    searchDsRequests: '',
    showModalSearchDsRequests: true
  },

  getters: {
    isLoading(state: any) {
      return state.loading
    }
  },

  mutations: {
    setLoading(state: any, value: any) {
      state.loading = value
    }
  }
}
