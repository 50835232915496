import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'
import common from '@/store/common'
import { Http } from '@/apis/Http'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notViewedCount: 0,
    notViewedIds: [],
    hasNotifications: 0
  },
  mutations: {
    setHasNotifications(state, value) {
      state.hasNotifications = value
    },

    setViewed(state, ids) {
      if (!ids.length) return
      for (const id of ids) {
        // @ts-ignore
        const index = state.notViewedIds.indexOf(id)
        if (index !== -1) {
          state.notViewedIds.splice(index, 1)
          state.notViewedCount = state.notViewedIds.length
        }
      }
      console.log('viewed', ids, state.notViewedIds)
    },

    setNotViewed(state, id) {
      // @ts-ignore
      const index = state.notViewedIds.indexOf(id)
      if (index === -1) {
        // @ts-ignore
        state.notViewedIds.push(id)
        state.notViewedCount = state.notViewedIds.length
      }
      console.log('not', id, state.notViewedIds)
    }
  },
  actions: {
    getNotViewed({ state }) {
      Http.get('/chat/get-total-not-viewed').then(res => {
        if (res?.data) {
          state.notViewedIds = res.data.notViewed
          state.notViewedCount = state.notViewedIds?.length ?? 0
          state.hasNotifications = res.data.hasNotifications
        }
      })
    }
  },

  modules: {
    auth,
    common
  }
})
