// import Auth from '@/Services/Auth'
import store from '@/store'
// import router from '@/router'

export default function (roles: string) {
  return ({ next }) => {
    // const orRoles = roles.split('|').map(rolesWithAnd => rolesWithAnd.split('&'))
    // const userHasAccess = orRoles.some(
    //   andRoles => andRoles.every(role => Auth.user()?.hasRole(role) ?? false)
    // )
    const userHasAccess = roles.split('|').some(
      (role) => store.getters['auth/userRole'] === role
    )

    if (!userHasAccess) {
      return next({ name: 'Home' })
    }
    return next()
  }
}
