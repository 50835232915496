
import Vue from 'vue'
import Component from 'vue-class-component'
import TheSidebar from '@/views/layouts/TheSidebar.vue'
import TheTopbar from '@/views/layouts/TheTopbar.vue'
import TheFooter from '@/views/layouts/TheFooter.vue'
import TheLoader from '@/views/components/TheLoader.vue'
import { mapGetters } from 'vuex'
// @ts-ignore
import DsTelegramModal from '@/views/components/DsTelegramModal'
// @ts-ignore
import LcTelegramModal from '@/views/components/LcTelegramModal'
// @ts-ignore
import WelcomeModal from '@/views/components/WelcomeModal'

@Component({
  name: 'App',
  components: {
    TheFooter,
    TheTopbar,
    TheSidebar,
    TheLoader,
    DsTelegramModal,
    LcTelegramModal,
    WelcomeModal
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width < 1500
    }
  }
})

export default class App extends Vue {
  heading = ''
  fixedNavigationDrawer = false
  showMenu = false
  showTgModal = false

  get isLoading() {
    return this.$store.getters['common/isLoading']
  }

  created() {
    this.controlMenu()
    this.showMenu = this.$vuetify.breakpoint.width >= 600
    if (!localStorage.getItem('tgModalClosed')) this.showTgModal = true
  }

  controlMenu() {
    this.fixedNavigationDrawer = this.$vuetify.breakpoint.width > 1060
  }

  closeTgModal() {
    localStorage.setItem('tgModalClosed', '1')
    this.showTgModal = false
  }
}
