import { Http } from '@/apis/Http'

export const User = {
  saveNameAndPhone(form: any) {
    return Http.post('/user/save-name-phone', {
      firstName: form.firstName,
      phone: form.phone
    })
  },

  getMenu() {
    return Http.get('/get-menu')
  },

  fetchUsers(categoryId: any) {
    return Http.get('/admin/get-users', {
      params: {
        categoryId: categoryId
      }
    })
  },

  fetchActiveUsers(categoryId: number) {
    return Http.get('/admin/get-active-users', {
      params: {
        categoryId: categoryId
      }
    })
  },

  fetchDsManagers() {
    return Http.get('/admin/get-ds-managers')
  },

  fetchComboboxesForAddUser() {
    return Http.get('/admin/fetch-comboboxes-for-add-user')
  },

  fetchDetailsForUpdateUser(id: any) {
    return Http.get(`/admin/fetch-details-for-update-user/${id}`)
  },

  storeUser(params: any) {
    return Http.post('/admin/users', params)
  },

  updateUser(params: any, id: any) {
    return Http.put(`/admin/users/${id}`, params)
  },

  deleteUser(id: any) {
    return Http.delete(`/admin/users/${id}`)
  },

  changePasswordManagerDs(userId: any) {
    return Http.get('/admin/change-password-manager-ds', {
      params: {
        userId
      }
    })
  },

  sendBannerUser(user: any) {
    return Http.post('/send-banner-user', user)
  },

  getTgUsersCount() {
    return Http.get('/admin/get-tg-users-count')
  }

}
