
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import NotificationDialog from '@/views/components/NotificationDialog.vue'
import { Http } from '@/apis/Http'
import TrialInfoDialog from '@/views/components/TrialInfoDialog.vue'

@Component({
  name: 'TheTopbar',
  components: { NotificationDialog, TrialInfoDialog },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
})

export default class TheTopbar extends Vue {
  @Prop(String) heading!: string
  @Prop(Boolean) fixedNavigationDrawer!: boolean
  // @PropSync('fixedNavigationDrawer', { type: Boolean }) syncFixedNavDrawer!: boolean
  @PropSync('showMenu', { type: Boolean }) syncShowMenu!: boolean

  showNotificationDialog = false
  notifications = []
  trialDialog = false

  // userName = ''
  //
  // created() {
  //   this.userName = (this.user.last_name ?? '') + ' ' + (this.user.first_name ?? '')
  // }

  created() {
    // @ts-ignore
    this.$socket.on('notification', () => {
      if (this.showNotificationDialog) this.getNotifications()
    })
  }

  get userName() {
    // @ts-ignore
    return (this.user.last_name ?? '') + ' ' + (this.user.first_name ?? '')
  }

  switchDialog() {
    if (this.showNotificationDialog) this.getNotifications()
  }

  getNotifications() {
    Http.get('/notifications/get-not-viewed').then(res => {
      if (res?.data) this.notifications = res.data
    })
  }

  toggleMenu () {
    this.syncShowMenu = !this.syncShowMenu
  }

  async logout() {
    // const userRole = this.$store.getters['auth/userRole']
    await this.$store.dispatch('auth/signOut')

    this.$router.push({ name: 'ChooseRole' })
  }
}
