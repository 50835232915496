
import Vue from 'vue'
import Component from 'vue-class-component'

@Component

export default class SystemNotificationMixin extends Vue {
  showSystemNotification(text: string, type: string) {
    this.$notify({
      group: 'system',
      text: text,
      type: type,
      duration: 3000
    })
  }

  showOkSystemNotification(text: string) {
    this.showSystemNotification(text, 'ok')
  }

  showSuccessSystemNotification(text: string) {
    this.showSystemNotification(text, 'success-request')
  }

  showErrorSystemNotification(text: string) {
    this.showSystemNotification(text, 'error-request')
  }

  showShatSystemNotification(text: string) {
    this.$notify({
      group: 'chat',
      text: text,
      type: 'success-request',
      duration: 5000
    })
  }

  showCongratsSystemNotification(text: string) {
    this.showSystemNotification(text, 'congrats')
  }
}
