import store from '@/store'

export default function ({ next }) {
  if (store.getters['auth/authenticated'] && store.getters['auth/token']) {
    return next({
      name: 'Home'
    })
  }

  return next()
}
