
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'TheLoader'
})

export default class TheLoader extends Vue {
  @Prop(Boolean) isLoading!: boolean
}
