<template>
  <svg class="custom-icon" preserveAspectRatio="xMinYMin meet" viewBox="0 0 24 24">
    <g transform="translate(0.05, 0.05)">
      <g>
        <path
          d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
          stroke="#878686"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        >
        </path>
        <path
          d="M9 22V12h6v10"
          stroke="#878686"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        >
        </path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon'
}
</script>
