import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import Notifications from 'vue-notification'
import vuetify from './plugins/vuetify'
import Csrf from '@/apis/Csrf'
import moment from 'moment'
import 'moment/locale/ru.js'
import SystemNotificationMixin from '@/mixins/SystemNotificationMixin.vue'
import Inputmask from 'inputmask'
import Auth from '@/Services/Auth'
import { VMoney } from 'v-money'
import WebSocket from './plugins/WebSocket'
// import WebSocket from '@/Services/WebSocket'
import DocumentVisibility from './plugins/DocumentVisibility'
import { system } from '@/utils/system'
moment.locale('ru')

Vue.config.productionTip = false

Vue.use(DocumentVisibility)
Vue.use(Notifications)
Vue.mixin(SystemNotificationMixin)
Vue.directive('money', VMoney)
Vue.directive('input-mask', {
  bind: function(el, binding) {
    const inputs = el.getElementsByTagName('INPUT')
    let input = inputs[0]
    if (inputs.length > 1) {
      input = inputs[inputs.length - 1]
    }
    new Inputmask(binding.value).mask(input)
  }
})

Vue.use(WebSocket)

const token = system.getCookie('token')
if (token) {
  store.commit('auth/SET_TOKEN', token)
}

Csrf.getCookie()
  .then(() => store.dispatch('auth/user'))
  .then(() => {
    Vue.prototype.$setLoading = (value: boolean) => store.commit('common/setLoading', value)
    Vue.prototype.$auth = Auth
    // Vue.prototype.$webSocket = WebSocket
    // Vue.use(WebSocket)

    new Vue({
      router,
      store,
      vuetify,
      // @ts-ignore
      Notifications,
      render: h => h(App)
    }).$mount('#app')
  })
