<template>
  <!--<div class="mt-5 absolute-footer">-->
    <v-footer
        class="pt-2 pa-0"
        data-color="transparent"
        color="white"
        :absolute="!isMobile"
    >
      <v-col
          class="text-center"
          cols="12"
      >
        <p>
          <v-row justify="center">
            <v-col sm="12" md="auto" cols="12">&copy; {{ new Date().getFullYear() }} poiskpl.ru</v-col>
            <v-col md="auto" sm="12" cols="12" class="pa-0 pa-md-3">
          Техническая поддержка <a href="mailto:support@poiskpl.ru">support@poiskpl.ru</a>
            </v-col>
            <v-col sm="12" md="auto" cols="12">
                <a class="text-decoration-none" href="/legal/rules_for_using" target="_blank">Правила пользования Сайтом</a>
            </v-col>
          </v-row>
        </p>
      </v-col>
    </v-footer>
  <!--</div>-->
</template>
<style>
  .absolute-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
</style>
<script>
  export default {
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.width < 1024
      }
    }
  }
</script>
<!--
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'TheFooter'
})

export default class TheFooter extends Vue {

}
</script>
-->
