<template>
  <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 276.747 276.747"
          width="24"
          height="24"
          class="custom-icon"
          xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
      <g>
        <path d="m226.319,151.005c-13.534,0-24.548,11.008-24.548,24.548 0,13.534 11.014,24.548 24.548,24.548 13.534,0 24.548-11.014 24.548-24.548-0.001-13.54-11.008-24.548-24.548-24.548zm0,31.801c-4.005,0-7.259-3.254-7.259-7.253s3.26-7.259 7.259-7.259c3.999,0 7.259,3.26 7.259,7.259s-3.254,7.253-7.259,7.253z"/>
        <path d="m47.966,151.005c-13.534,0-24.548,11.008-24.548,24.548 0,13.534 11.014,24.548 24.548,24.548s24.553-11.014 24.553-24.548c0-13.54-11.019-24.548-24.553-24.548zm0,31.801c-4.005,0-7.253-3.254-7.253-7.253s3.254-7.259 7.253-7.259 7.259,3.26 7.259,7.259-3.26,7.253-7.259,7.253z"/>
        <rect width="81.938" x="96.176" y="171.001" height="17.289"/>
        <polygon points="271.799,120.124 255.203,114.885 209.467,114.885 185.28,76.647 45.358,76.647     16.2,117.511 4.907,131.406 0,154.62 0,171.915 17.283,171.915 17.283,156.431 20.956,139.066 29.955,127.995 54.259,93.935     175.757,93.935 199.949,132.18 252.537,132.18 258.184,133.955 259.214,137.041 256.181,171.152 273.406,172.677 276.747,134.997       "/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CarBrandIcon'
}
</script>
