<template>
  <!--<svg xmlns="http://www.w3.org/2000/svg" class="custom-icon"  width="24" height="24" viewBox="0 0 24 24">
    <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16Z"
          stroke="#878686"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
    />
  </svg>-->
  <v-icon>mdi-message-outline</v-icon>
</template>

<script>
export default {
  name: 'MessageIcon'
}
</script>
