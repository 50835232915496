export default {
  install(Vue) {
    function getHiddenProp() {
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        return 'hidden'
      } else if (typeof document.msHidden !== 'undefined') {
        return 'msHidden'
      } else if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitHidden'
      }
    }

    function getEventName() {
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        return 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        return 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitvisibilitychange'
      }
    }

    Vue.prototype.$isDocumentVisible = function() {
      const hiddenProp = getHiddenProp()
      return hiddenProp ? !document[hiddenProp] : true
    }

    Vue.prototype.$listenDocumentVisibility = function(callback) {
      document.addEventListener(getEventName(), callback, false)
    }

    Vue.prototype.$removeVisibilityListener = function(callback) {
      document.removeEventListener(getEventName(), callback)
    }
  }
}
