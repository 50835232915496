<template>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <path
      d="M2.5 2v6h6m-5.84 7.57a10 10 0 10.57-8.38"
      stroke="#3A76F0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </svg>
</template>

<script>
export default {
  name: 'RotateIcon'
}
</script>
