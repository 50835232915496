import store from '@/store'

export default function ({ next }) {
  if (!store.getters['auth/authenticated'] || !store.getters['auth/token']) {
    store.commit('auth/SET_USER', false)
    store.commit('auth/SET_TOKEN', '')
    store.commit('auth/SET_AUTHENTICATED', false)
    return next({
      name: 'ChooseRole'
    })
  }
  return next()
}
