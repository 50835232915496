export const system = {
  getCookie(name): string|undefined {
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + '=([^;]*)'
    ))
    return matches ? decodeURIComponent(matches[1]) : undefined
  },

  getFileLink(path) {
    return process.env.NODE_ENV === 'development' ? 'http://188.225.11.172:8000/api' + path : 'http://188.225.11.172:8000/api' + path
  }
}
