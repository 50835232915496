<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5 0C6.48992 0 0 6.48992 0 14.5C0 22.5101 6.48992 29 14.5 29C22.5101 29 29 22.5101 29 14.5C29 6.48992 22.5101 0 14.5 0ZM21.6214 9.93367L19.2417 21.1478C19.0663 21.9429 18.5927 22.1359 17.9321 21.7617L14.3071 19.0897L12.5589 20.7736C12.3659 20.9665 12.2022 21.1302 11.828 21.1302L12.0853 17.4409L18.8032 11.372C19.0956 11.1147 18.7389 10.9685 18.353 11.2258L10.0506 16.4528L6.47238 15.3361C5.69476 15.0905 5.67722 14.5585 6.63609 14.1843L20.6157 8.79355C21.2647 8.55968 21.8319 8.95141 21.6214 9.93367Z" fill="#50A2E9"/>
  </svg>
</template>

<script>
  export default {
    name: 'TelegramIcon'
  }
</script>
