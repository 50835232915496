import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import VueYandexMetrika from 'vue-yandex-metrika'
import store from '@/store'
import auth from '@/router/middleware/auth'
import guest from '@/router/middleware/guest'
import role from '@/router/middleware/role'
import middlewarePipeline from '@/router/middlewarePipeline'

const host = window.location.host
const parts = host.split('.')

const isFavoriteLc = parts[0] === 'poisk-pl'
const loginUrl = isFavoriteLc ? '/login' : '/login/:userRole'

Vue.use(VueRouter)

// console.log('---')
// console.log(role)
// console.log('---')

const routes: Array<RouteConfig> = [

  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/index.vue'),
    meta: {
      header: 'ПоискПЛ - сервис для быстрого поиска предмета лизинга',
      middleware: [auth]
    }
  },
  {
    path: '/:interface/requests',
    name: 'AllRequestsFromDiler',
    component: () => import('@/views/RequestList/index.vue'),
    meta: {
      header: 'Запросы на лизинг со стороны ДЦ',
      middleware: [auth]
    }
  },
  {
    path: '/dc/request/:id',
    name: 'DealerRequest',
    component: () => import('@/views/DealerRequest/index.vue'),
    meta: {
      header: 'Заявка со стороны ДЦ',
      middleware: [
        auth
        // role('managerDs')
      ]
    }
  },
  {
    path: '/lc/request/:id',
    name: 'LeasingRequest',
    component: () => import('@/views/LeasingRequest/index.vue'),
    meta: {
      header: 'Заявка со стороны ДЦ',
      middleware: [
        auth
        // role('managerDs')
      ]
    }
  },
  {
    path: '/admin/request/:id',
    name: 'DealerRequestInAdmin',
    component: () => import('@/views/DealerRequest/index.vue'),
    meta: {
      header: 'Заявка со стороны ДЦ',
      middleware: [
        auth
        // role('managerDs')
      ]
    }
  },
  {
    path: '/dc/request',
    name: 'NewDealerRequest',
    component: () => import('@/views/DealerRequest/index.vue'),
    meta: {
      header: 'Заявка со стороны ДЦ',
      middleware: [
        auth
        // role('managerDs')
      ]
    }
  },
  {
    path: '/legal/rules_for_using',
    name: 'legal-rules',
    component: () => import('@/views/Legal/Rules.vue'),
    meta: {
      header: 'ПоискПЛ - Политика платформы'
      // middleware: [auth]
    }
  },
  {
    path: '/legal/cookie',
    name: 'legal-cookie',
    component: () => import('@/views/Legal/Cookie.vue'),
    meta: {
      header: 'ПоискПЛ - Политика платформы'
      // middleware: [auth]
    }
  },
  {
    path: '/legal/personal',
    name: 'legal-personal',
    component: () => import('@/views/Legal/Personal.vue'),
    meta: {
      header: 'ПоискПЛ - Политика платформы'
      // middleware: [auth]
    }
  },
  {
    path: '/bannerlc',
    name: 'banner-lc',
    component: () => import('@/views/Landing/index.vue'),
    meta: {
      header: 'Poiskpl.ru'
      // middleware: [auth]
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('@/views/Chat/Chat.vue'),
    meta: {
      header: 'ПоискПЛ - сервис для быстрого поиска предмета лизинга',
      middleware: [auth]
    }
  },
  {
    path: '/chat/:chat_id',
    name: 'Chat',
    component: () => import('@/views/Chat/Chat.vue'),
    meta: {
      header: 'ПоискПЛ - сервис для быстрого поиска предмета лизинга',
      middleware: [auth]
    }
  },
  {
    path: '/admin/chats',
    name: 'Chats',
    component: () => import('@/views/Admin/Chats/Chats.vue'),
    meta: {
      header: 'ПоискПЛ - Чаты',
      middleware: [auth]
    }
  },
  {
    path: '/admin/chats/:chat_id',
    name: 'Chats',
    component: () => import('@/views/Admin/Chats/Chats.vue'),
    meta: {
      header: 'ПоискПЛ - Чаты',
      middleware: [auth]
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/Notifications/Notifications.vue'),
    meta: {
      header: 'ПоискПЛ - уведомления',
      middleware: [auth]
    }
  },
  {
    path: '/clients',
    name: 'AllClients',
    component: () => import('@/views/Clients/index.vue'),
    meta: {
      header: 'Все клиенты',
      middleware: [auth]
    }
  },
  {
    path: '/leas-request',
    name: 'LeasRequest',
    component: () => import('@/views/LeasRequest/index.vue'),
    meta: {
      header: 'Запрос',
      middleware: [auth]
    }
  },
  {
    path: '/search-leas-objects/new',
    name: 'SearchLeasObjectsNewRequest',
    component: () => import('@/views/SearchLeasObject/SearchLeasObjectNewRequest.vue'),
    meta: {
      header: 'Поиск предмета лизинга',
      middleware: [
        auth
        // role('managerLc')
      ]
    }
  },
  {
    path: '/search-leas-objects/archive',
    name: 'SearchLeasObjectArchive',
    component: () => import('@/views/SearchLeasObject/SearchLeasObjectArchive.vue'),
    meta: {
      header: 'Архивные запросы',
      middleware: [
        auth
        // role('managerLc')
      ]
    }
  },
  {
    path: '/search-leas-objects',
    name: 'searchLeasObjectHistoryOfRequests',
    component: () => import('@/views/SearchLeasObject/SearchLeasObjectHistoryOfRequests.vue'),
    meta: {
      header: 'История запросов',
      middleware: [
        auth
        // role('managerLc')
      ]
    }
  },
  {
    path: '/search-leas-objects/:id',
    name: 'SearchLeasObjectDetails',
    component: () => import('@/views/SearchLeasObject/SearchLeasObjectDetails.vue'),
    meta: {
      header: 'Запрос на поиск транспортного средства',
      middleware: [
        auth
        // role('managerLc')
      ]
    }
  },
  {
    path: '/request-leas-objects',
    name: 'RequestLeasObjectsList',
    component: () => import('@/views/RequestLeasObjects/RequestLeasObjectsList.vue'),
    meta: {
      header: 'Все запросы на поиск транспортного средства',
      middleware: [
        auth
        // role('managerDs')
      ]
    }
  },
  {
    path: '/request-leas-objects/archive',
    name: 'RequestLeasObjectsListArchive',
    component: () => import('@/views/RequestLeasObjects/RequestLeasObjectsArchive.vue'),
    meta: {
      header: 'Архивные запросы',
      middleware: [
        auth
        // role('managerDs')
      ]
    }
  },
  {
    path: '/request-leas-objects/:id',
    name: 'RequestLeasObject',
    component: () => import('@/views/RequestLeasObjects/RequestLeasObcjectsDetails/index.vue'),
    meta: {
      header: 'Запрос на поиск транспортного средства',
      middleware: [
        auth
        // role('managerDs')
      ]
    }
  },
  {
    path: '/dealerships',
    name: 'Dealerships',
    component: () => import('@/views/Dealerships/DealershipsList.vue'),
    meta: {
      header: 'Все автосалоны',
      middleware: [
        auth
        // role('managerLc')
      ]
    }
  },
  {
    path: '/dealerships/new',
    name: 'DealershipsAdding',
    component: () => import('@/views/Dealerships/DealershipsAdding/index.vue'),
    meta: {
      header: 'Добавить новый автосалон',
      middleware: [
        auth
        // role('managerLc')
      ]
    }
  },
  {
    path: '/account/settings',
    name: 'AccountSettings',
    component: () => import('@/views/Account/AccountSettings.vue'),
    meta: {
      header: 'Настройки',
      middleware: [
        auth,
        role('managerLc|managerDs|admin')
      ]
    }
  },
  {
    path: '/admin/car-search-requests',
    name: 'CarSearchRequests',
    component: () => import('@/views/Admin/CarSearchRequests/CarSearchRequests.vue'),
    meta: {
      header: 'Заявки на поиск ТС',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/car-search-requests/archive',
    name: 'CarSearchRequestsArchive',
    component: () => import('@/views/Admin/CarSearchRequests/CarSearchRequestsArchive.vue'),
    meta: {
      header: 'Архивные запросы',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/car-search-requests/:id',
    name: 'CarSearchRequest',
    component: () => import('@/views/Admin/CarSearchRequests/CarSearchRequest.vue'),
    meta: {
      header: 'Запрос на поиск транспортного средства',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/partners',
    name: 'Partners',
    component: () => import('@/views/Admin/Partners/Partners.vue'),
    meta: {
      header: 'Партнеры',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/partners/new',
    name: 'AddPartner',
    component: () => import('@/views/Admin/Partners/AddPartner.vue'),
    meta: {
      header: 'Добавить нового партнера',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/partners/:categoryId/:id/edit',
    name: 'EditPartner',
    component: () => import('@/views/Admin/Partners/EditPartner.vue'),
    meta: {
      header: 'Редактирование партнера',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/partners/:categoryId/:id/subscription',
    name: 'SubscribePartner',
    component: () => import('@/views/Admin/Partners/SubscribePartner.vue'),
    meta: {
      header: 'Редактирование партнера',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/partners/:categoryId/:id/back-users',
    name: 'SubscribePartner',
    component: () => import('@/views/Admin/Partners/BackUsers.vue'),
    meta: {
      header: 'Добавить менеджера бэка',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/account/partners',
    name: 'AccountPartners',
    component: () => import('@/views/Account/Partners/Partners.vue'),
    meta: {
      header: 'Партнеры',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/account/partners/new',
    name: 'AccountAddPartner',
    component: () => import('@/views/Account/Partners/AddPartner.vue'),
    meta: {
      header: 'Добавить нового партнера',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/account/partners/:categoryId/:id/edit',
    name: 'AccountEditPartner',
    component: () => import('@/views/Account/Partners/EditPartner.vue'),
    meta: {
      header: 'Редактирование партнера',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/account/partners/:categoryId/:id/subscription',
    name: 'AccountSubscribePartner',
    component: () => import('@/views/Account/Partners/SubscribePartner.vue'),
    meta: {
      header: 'Редактирование партнера',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/account/partners/:categoryId/:id/back-users',
    name: 'AccountSubscribePartner',
    component: () => import('@/views/Account/Partners/BackUsers.vue'),
    meta: {
      header: 'Добавить менеджера бэка',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/admin/reports/lc-reports',
    name: 'LcReports',
    component: () => import('@/views/Admin/Reports/LcReports.vue'),
    meta: {
      header: 'Отчеты по ЛК',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/reports/lc-reports-ext',
    name: 'LcReportsExt',
    component: () => import('@/views/Admin/Reports/LcReportsExt.vue'),
    meta: {
      header: 'Расширенные отчеты по ЛК',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/reports/ds-reports',
    name: 'dsReports',
    component: () => import('@/views/Admin/Reports/DsReports.vue'),
    meta: {
      header: 'Отчеты по ДЦ',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: () => import('@/views/Admin/Users/Users.vue'),
    meta: {
      header: 'Пользователи',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/users/new',
    name: 'AddUser',
    component: () => import('@/views/Admin/Users/AddUser.vue'),
    meta: {
      header: 'Добавить пользователя',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/users/new/:categoryId/:partnerId/',
    name: 'AddUserToPartner',
    component: () => import('@/views/Admin/Users/AddUser.vue'),
    meta: {
      header: 'Добавить пользователя',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/users/:id/edit',
    name: 'EditUser',
    component: () => import('@/views/Admin/Users/EditUser.vue'),
    meta: {
      header: 'Редактировать пользователя',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/moderations',
    name: 'Moderations',
    component: () => import('@/views/Admin/Moderations/Moderations.vue'),
    meta: {
      header: 'Модерация',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/moderations/:id/edit',
    name: 'EditModeration',
    component: () => import('@/views/Admin/Moderations/EditModeration.vue'),
    meta: {
      header: 'Добавить автосалон',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/brands',
    name: 'Brands',
    component: () => import('@/views/Admin/Brands/Brands.vue'),
    meta: {
      header: 'Марки',
      middleware: [auth, role('admin')]
    }
  },
  {
    path: '/admin/brands/:id/edit',
    name: 'EditCarBrand',
    component: () => import('@/views/Admin/Brands/EditBrand.vue'),
    meta: {
      header: 'Редактирование марки',
      middleware: [auth, role('admin')]
    }
  },
  {
    path: '/admin/brands/add',
    name: 'AddBrand',
    component: () => import('@/views/Admin/Brands/addBrand.vue'),
    meta: {
      header: 'Добавить марку',
      middleware: [auth, role('admin')]
    }
  },
  {
    path: '/admin/brands/:id/models/add',
    name: 'AddCarModelToCarBrand',
    component: () => import('@/views/Admin/Brands/addCarModel.vue'),
    meta: {
      header: 'Добавить модель',
      middleware: [auth, role('admin')]
    }
  },
  {
    path: '/admin/brands/:id/models/:modelId/edit',
    name: 'EditCarModel',
    component: () => import('@/views/Admin/Brands/editCarModel.vue'),
    meta: {
      header: 'Редактировать модель',
      middleware: [auth, role('admin')]
    }
  },

  {
    path: '/admin/moderations/:id/edit',
    name: 'EditBrands',
    component: () => import('@/views/Admin/Brands/EditBrand.vue'),
    meta: {
      header: 'Редактировать марку',
      middleware: [auth, role('admin')]
    }
  },
  {
    path: '/admin/logs',
    name: 'Logs',
    component: () => import('@/views/Admin/Logs/index.vue'),
    meta: {
      header: 'Логи',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/support',
    name: 'Support',
    component: () => import('@/views/Admin/Support/index.vue'),
    meta: {
      header: 'Сопровождение',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/stat',
    name: 'Stat',
    component: () => import('@/views/Admin/Stat/index.vue'),
    meta: {
      header: 'Статистика',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },
  {
    path: '/admin/statLc',
    name: 'StatLc',
    component: () => import('@/views/Admin/Stat/statLc.vue'),
    meta: {
      header: 'Статистика ЛК',
      middleware: [
        auth,
        role('admin')
      ]
    }
  },

  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/Other/index.vue'),
    meta: {
      header: 'Как это работает ?',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/diagnostics',
    name: 'Diagnostics',
    component: () => import('@/views/Other/Diagnostics.vue'),
    meta: {
      header: 'Диагностика',
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/all-requests',
    name: 'SuperviserRequests',
    component: () => import('@/views/SuperviserLc/SuperviserLcList.vue'),
    meta: {
      header: 'Все запросы',
      middleware: [
        auth,
        role('superviser')
      ]
    }
  },
  {
    path: '/reports',
    name: 'SuperviserReports',
    component: () => import('@/views/SuperviserLc/SuperviserLcReport.vue'),
    meta: {
      header: 'Отчет',
      middleware: [
        auth,
        role('superviser')
      ]
    }
  },
  {
    path: '/confirm/:token',
    name: 'EmailConfirmation',
    component: () => import('@/views/Auth/EmailConfirmation.vue'),
    meta: {
      header: 'Подтверждение e-mail',
      middleware: [guest]
    }
  },
  {
    path: '',
    component: () => import('@/views/Auth/index.vue'),
    children: [
      {
        path: '/change-password',
        name: 'PasswordChange',
        component: () => import('@/views/Auth/PasswordChange.vue'),
        meta: {
          header: 'Изменение пароля',
          middleware: [guest],
          bodyId: 'auth'
        }
      },
      {
        path: '/set-password',
        name: 'PasswordSet',
        component: () => import('@/views/Auth/PasswordChange.vue'),
        meta: {
          header: 'Установка пароля',
          middleware: [guest],
          bodyId: 'auth'
        }
      },
      {
        path: loginUrl,
        name: 'Login',
        component: () => import('@/views/Auth/Login.vue'),
        meta: {
          header: 'ПоискПЛ - сервис для быстрого поиска предмета лизинга',
          middleware: [guest],
          bodyId: 'auth'
        }
      },
      {
        path: '/choose-role',
        name: 'ChooseRole',
        component: () => import('@/views/Auth/ChooseRole.vue'),
        meta: {
          header: 'ПоискПЛ - сервис для быстрого поиска предмета лизинга',
          middleware: [guest],
          bodyId: 'auth'
        }
      },
      {
        path: '/registration/:user_role_id',
        name: 'Registration',
        component: () => import('@/views/Auth/Registration.vue'),
        meta: {
          header: 'ПоискПЛ - сервис для быстрого поиска предмета лизинга',
          middleware: [guest],
          bodyId: 'auth'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('@/views/ErrorPage/PageNotFound.vue'),
    meta: {
      header: 'Ошибка 404 - Страница не найдена',
      middleware: [guest]
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

if (location.host === 'poiskpl.ru') {
  Vue.use(VueYandexMetrika, {
    id: 71104474,
    router: router,
    options: { clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true },
    env: process.env.NODE_ENV
  })
}

router.beforeEach((to, from, next) => {
  if (!to.meta?.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.header ? to.meta?.header : 'Автолизинг'
  })
})

export default router
