import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import HomeIcon from '@/components/icons/HomeIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import RubleIcon from '@/components/icons/RubleIcon.vue'
import PercentIcon from '@/components/icons/PercentIcon.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import FlagIcon from '@/components/icons/FlagIcon.vue'
import BellIcon from '@/components/icons/BellIcon.vue'
import BlockIcon from '@/components/icons/BlockIcon.vue'
import ChatIcon from '@/components/icons/ChatIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import CheckboxCircleIcon from '@/components/icons/CheckboxCircleIcon.vue'
import CompanyIcon from '@/components/icons/CompanyIcon.vue'
import ChevronIcon from '@/components/icons/ChevronIcon.vue'
import UserPlusIcon from '@/components/icons/UserPlusIcon.vue'
import WatchIcon from '@/components/icons/WatchIcon.vue'
import MonitorIcon from '@/components/icons/MonitorIcon.vue'
import FlameIcon from '@/components/icons/FlameIcon.vue'
import ListIcon from '@/components/icons/ListIcon.vue'
import FileAddIcon from '@/components/icons/FileAddIcon.vue'
import GridIcon from '@/components/icons/GridIcon.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import MultipleUsers from '@/components/icons/MultipleUsers.vue'
import LineChartIcon from '@/components/icons/LineChartIcon.vue'
import RotateIcon from '@/components/icons/RotateIcon.vue'
import NoCarIcon from '@/components/icons/NoCarIcon.vue'
import VideoIcon from '@/components/icons/VideoInstructionIcon.vue'
import CarBrandIcon from '@/components/icons/CarBrandIcon.vue'
import LogsIcon from '@/components/icons/LogsIcon.vue'
import adminSupportIcon from '@/components/icons/SupportIcon.vue'
import MessageIcon from '@/components/icons/MessageIcon.vue'
import ArchiveIcon from '@/components/icons/ArchiveIcon.vue'
import ReportsIcon from '@/components/icons/ReportsIcon.vue'
import TelegramIcon from '@/components/icons/TelegramIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  font: {
    family: 'Open Sans'
  },
  theme: {
    themes: {
      light: {
        primary: '#3A76F0',
        primaryshade: '#EBF9FF',
        secondary: '#101B53',
        secondaryshade: '#EBF9FF',
        error: '#F35555',
        errorshade: '#FEF3F3',
        success: '#22D27D',
        successshade: '#EFFCF6',
        warning: '#FFC107'
      }
    }
  },
  icons: {
    values: {
      home: {
        component: HomeIcon
        // name: 'home'
      },
      search: {
        component: SearchIcon
        // name: 'search'
      },
      ruble: {
        component: RubleIcon
        // name: 'ruble'
      },
      percent: {
        component: PercentIcon
        // name: 'percent'
      },
      calendar: {
        component: CalendarIcon
        // name: 'calendar'
      },
      flag: {
        component: FlagIcon
        // name: 'flag'
      },
      bell: {
        component: BellIcon
        // name: 'bell'
      },
      block: {
        component: BlockIcon
        // name: 'block'
      },
      chat: {
        component: ChatIcon
        // name: 'chat'
      },
      check: {
        component: CheckIcon
        // name: 'check'
      },
      checkboxCircle: {
        component: CheckboxCircleIcon
        // name: 'checkboxCircle'
      },
      company: {
        component: CompanyIcon
        // name: 'company'
      },
      chevron: {
        component: ChevronIcon
        // name: 'chevron'
      },
      userPlus: {
        component: UserPlusIcon
        // name: 'userPlus'
      },
      watch: {
        component: WatchIcon
        // name: 'watch'
      },
      monitor: {
        component: MonitorIcon
        // name: 'monitor'
      },
      flame: {
        component: FlameIcon
        // name: 'flame'
      },
      list: {
        component: ListIcon
        // name: 'list'
      },
      fileAdd: {
        component: FileAddIcon
        // name: 'fileAdd'
      },
      grid: {
        component: GridIcon
        // name: 'grid'
      },
      settings: {
        component: SettingsIcon
        // name: 'settings'
      },
      multipleUsers: {
        component: MultipleUsers
        // name: 'multipleUsers'
      },
      lineChart: {
        component: LineChartIcon
        // name: 'lineChart'
      },
      rotate: {
        component: RotateIcon
        // name: 'rotate'
      },
      noCar: {
        component: NoCarIcon
        // name: 'noCar'
      },
      carBrand: {
        component: CarBrandIcon
        // name: 'carBrand'
      },
      videoInstruction: {
        component: VideoIcon
        // name: 'videoInstruction'
      },
      adminLogs: {
        component: LogsIcon
        // name: 'adminLogs'
      },
      adminSupport: {
        component: adminSupportIcon
        // name: 'adminSupport'
      },
      message: {
        component: MessageIcon
        // name: 'message'
      },
      archive: {
        component: ArchiveIcon
        // name: 'archive'
      },
      reports: {
        component: ReportsIcon
        // name: 'reports'
      },
      telegram: {
        component: TelegramIcon
        // name: 'telegram'
      }
    }
  }
})
