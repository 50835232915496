<template>
  <v-card class="notification-dialog" max-height="90vh" elevation="0">
    <v-card-title>
      <div>Уведомления</div>
      <v-spacer></v-spacer>
      <v-btn class="set-all-viewed-btn" text @click="setAllViewed()">Отметить все прочитанными</v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-list>
        <v-list-item v-for="item in notifications" :key="item.id" class="pl-0" :class="{'not-viewed': !item.viewed}">
          <v-list-item-avatar>
            <v-btn v-if="!item.viewed" color="primary" @click="switchViewed(item)" icon>
              <v-icon>mdi-circle-medium</v-icon>
            </v-btn>
            <v-btn v-if="item.viewed" @click="switchViewed(item)" icon>
              <v-icon>mdi-circle-medium</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content @click="goToRequest(item)" class="cursor-pointer">
            <v-list-item-subtitle>{{formatDate(item.created_at)}}</v-list-item-subtitle>
            <v-list-item-title>{{getText(item)}}</v-list-item-title>
            <div class="request-id">Запрос №{{item.request_id}}</div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('close')" to="/notifications" block outlined>Все уведомления</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import moment from 'moment'
// import { Http } from '@/apis/Http'
import NotificationCommon from '@/views/components/NotificationCommon'

export default {
  props: ['notifications'],

  methods: {
    goToRequest(item) {
      if (!item.request_id) return
      item.viewed = 1
      this.checkAll()
      let url
      if (this.$store.state.auth.userRole === 'manager-ds') {
        url = '/request-leas-objects/' + item.request_id
      } else {
        url = '/search-leas-objects/' + item.request_id
      }
      if (this.$route.path !== url) this.$router.push(url)
    },

    ...NotificationCommon
  }
}
</script>
