<template>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="custom-icon"
  >
    <path
      d="M5.25 15c0-4.36 5.813-7.734 4.5-12.75 3.094 0 9 4.5 9 12.75a6.75 6.75 0 01-13.5 0v0z"
      stroke="#3A76F0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M15 17.25c0 2.705-1.5 3.75-3 3.75s-3-1.045-3-3.75 1.875-4.031 1.5-6c1.969 0 4.5 3.295 4.5 6z"
      stroke="#3A76F0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </svg>
</template>

<script>
export default {
  name: 'FlameIcon'
}
</script>
