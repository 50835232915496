<template>
  <v-dialog
    v-model="showTgModal"
    overlay-opacity="0.8"
    max-width="640"
    scrollable
  >
    <v-card class="pb-10">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="text-center" style="font-size: 18px">
        Теперь Вы можете получать статусы по запросам и <br>резервировать автомобили через Telegram-бота
      </v-card-subtitle>
      <v-card-text>
        <p>
          Мы сделали помощника - Telegram-бота, чтобы Вы могли оперативно и удобно получать информацию о новых предложениях на запросы по поиску авто.
          Также через  Telegram-бота Вы можете резервировать авто в автосалонах.
        </p>
        <p class="text-center">
          <v-btn large color="secondary" outlined :href="$store.state.auth.user.tg_link" target="_blank">
            <span class="mt-1">
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 0C6.48992 0 0 6.48992 0 14.5C0 22.5101 6.48992 29 14.5 29C22.5101 29 29 22.5101 29 14.5C29 6.48992 22.5101 0 14.5 0ZM21.6214 9.93367L19.2417 21.1478C19.0663 21.9429 18.5927 22.1359 17.9321 21.7617L14.3071 19.0897L12.5589 20.7736C12.3659 20.9665 12.2022 21.1302 11.828 21.1302L12.0853 17.4409L18.8032 11.372C19.0956 11.1147 18.7389 10.9685 18.353 11.2258L10.0506 16.4528L6.47238 15.3361C5.69476 15.0905 5.67722 14.5585 6.63609 14.1843L20.6157 8.79355C21.2647 8.55968 21.8319 8.95141 21.6214 9.93367Z" fill="#50A2E9"/>
              </svg>
             </span>
            <span class="ml-3">Запустить Telegram-бота</span>
          </v-btn>
        </p>
        <p>
          Если у Вас нет возможности запустить Telegram-бота с компьютера, Вы можете:
        </p>
          <ol>
            <li>Взять в руки свой смартфон</li>
            <li>Включить камеру на смартфоне</li>
            <li>Навести (не фотографировать) камеру смартфона на QR код ниже</li>
            <li>Открыть ссылку на Telegtam-бота PoiskPL, которая появится после наведения камеры на QR код</li>
            <li>В Telegram-боте нажать “Начать”</li>
          </ol>
        <v-row>
          <v-col cols="12" sm="auto">
            <div class="mt-8 text-center">
              <img src="@/assets/tg-modal.png">
            </div>
          </v-col>
          <v-col>
            <div class="text-center">
              <div class="mt-5" style="font-size: 20px">Ваш QR код</div>
              <div>Наведите на него камеру своего смартфона</div>
              <div v-html="qrcode" class="mt-3"></div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          depressed
          color="secondary"
          @click="$emit('close')"
          x-large
          class="mx-auto"
          width="250"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import { Http } from '@/apis/Http'

  export default {
    props: ['showTgModal'],
    data() {
      return {
        qrcode: ''
      }
    },
    created() {
      Http.get('/qrcode').then(res => {
        this.qrcode = res.data
      })
    }
  }
</script>
