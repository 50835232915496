<template>
    <svg class="custom-icon" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <g id="List">
            <path clip-rule="evenodd"
                  d="M3.25 3C3.25 1.48122 4.48122 0.25 6 0.25H19C20.5188 0.25 21.75 1.48122 21.75 3V21C21.75 22.5188 20.5188 23.75 19 23.75H6C4.48122 23.75 3.25 22.5188 3.25 21V3ZM6 1.75C5.30964 1.75 4.75 2.30964 4.75 3V21C4.75 21.6904 5.30964 22.25 6 22.25H19C19.6904 22.25 20.25 21.6904 20.25 21V3C20.25 2.30964 19.6904 1.75 19 1.75H6Z"
                   fill-rule="evenodd" id="Rectangle 10 (Stroke)"/>
            <path clip-rule="evenodd"
                  d="M8 4.75C7.86193 4.75 7.75 4.86193 7.75 5C7.75 5.13807 7.86193 5.25 8 5.25C8.13807 5.25 8.25 5.13807 8.25 5C8.25 4.86193 8.13807 4.75 8 4.75ZM6.25 5C6.25 4.0335 7.0335 3.25 8 3.25C8.9665 3.25 9.75 4.0335 9.75 5C9.75 5.9665 8.9665 6.75 8 6.75C7.0335 6.75 6.25 5.9665 6.25 5Z"
                   fill-rule="evenodd" id="Ellipse 3 (Stroke)"/>
            <path clip-rule="evenodd"
                  d="M8 11.75C7.86193 11.75 7.75 11.8619 7.75 12C7.75 12.1381 7.86193 12.25 8 12.25C8.13807 12.25 8.25 12.1381 8.25 12C8.25 11.8619 8.13807 11.75 8 11.75ZM6.25 12C6.25 11.0335 7.0335 10.25 8 10.25C8.9665 10.25 9.75 11.0335 9.75 12C9.75 12.9665 8.9665 13.75 8 13.75C7.0335 13.75 6.25 12.9665 6.25 12Z"
                   fill-rule="evenodd" id="Ellipse 4 (Stroke)"/>
            <path clip-rule="evenodd"
                  d="M8 18.75C7.86193 18.75 7.75 18.8619 7.75 19C7.75 19.1381 7.86193 19.25 8 19.25C8.13807 19.25 8.25 19.1381 8.25 19C8.25 18.8619 8.13807 18.75 8 18.75ZM6.25 19C6.25 18.0335 7.0335 17.25 8 17.25C8.9665 17.25 9.75 18.0335 9.75 19C9.75 19.9665 8.9665 20.75 8 20.75C7.0335 20.75 6.25 19.9665 6.25 19Z"
                   fill-rule="evenodd" id="Ellipse 5 (Stroke)"/>
            <path clip-rule="evenodd"
                  d="M12.25 5C12.25 4.58579 12.5858 4.25 13 4.25H19C19.4142 4.25 19.75 4.58579 19.75 5C19.75 5.41421 19.4142 5.75 19 5.75H13C12.5858 5.75 12.25 5.41421 12.25 5Z"
                   fill-rule="evenodd" id="Vector 5 (Stroke)"/>
            <path clip-rule="evenodd"
                  d="M12.25 12C12.25 11.5858 12.5858 11.25 13 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H13C12.5858 12.75 12.25 12.4142 12.25 12Z"
                   fill-rule="evenodd" id="Vector 6 (Stroke)"/>
            <path clip-rule="evenodd"
                  d="M12.25 19C12.25 18.5858 12.5858 18.25 13 18.25H19C19.4142 18.25 19.75 18.5858 19.75 19C19.75 19.4142 19.4142 19.75 19 19.75H13C12.5858 19.75 12.25 19.4142 12.25 19Z"
                   fill-rule="evenodd" id="Vector 7 (Stroke)"/>
        </g>
    </svg>

</template>

<script>
export default {
  name: 'LogsIcon'
}
</script>
