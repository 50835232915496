import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'
// import moment from 'moment'

export default {
  async login(form) {
    // await Csrf.getCookie()
    return Http.post('/login', form)
  },

  async logout() {
    // await Csrf.getCookie()
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=' + location.pathname
    return Http.post('/logout')
  },

  async register(form) {
    // await Csrf.getCookie()
    return Http.post('/register', form)
  },

  async confirm(token) {
    // await Csrf.getCookie()
    return Http.post('/confirm-email', { token })
  },

  async checkUserExist(email, roleId) {
    // await Csrf.getCookie()
    return Http.get('/check-user-exist', {
      params: {
        email: email,
        roleId: roleId
      }
    })
  },

  async checkToken(tokenName, email) {
    // await Csrf.getCookie()
    return Http.post('/check-token', {
      tokenName: tokenName,
      email: email
    })
  },

  async changePassword(email, password) {
    // await Csrf.getCookie()
    return Http.post('/change-password', {
      email: email,
      password: password
    })
  },

  async restorePassword(email, token) {
    console.log('Запрос идет')
    console.log(email)
    // await Csrf.getCookie()
    return Http.post('/restore-password', {
      email: email,
      token: token
    })
  },

  async details(userRoleId) {
    // await Csrf.getCookie()
    return Http.get('/login-details', {
      params: {
        userRoleId: userRoleId
      }
    })
  },

  async sendClickRestoreBtn(email: string) {
    return Http.get('/user-click-restore-btn', {
      params: {
        email: email
      }
    })
  },

  user() {
    return Http.get('/user')
  }
}
