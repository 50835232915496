<template>
  <svg class="custom-icon" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"
      stroke="#878686"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    >
    </path>
  </svg>
</template>

<script>

export default {
  name: 'SearchIcon'
}
</script>
