import moment from 'moment'
import { Http } from '../../apis/Http'

export default {
  formatDate(date) {
    return moment(date).format('LLL')
  },

  async switchViewed(item) {
    item.viewed = !item.viewed
    await Http.post('/notifications/set-viewed-state', { ids: [item.id], viewed: item.viewed })
    this.checkAll()
  },

  checkAll() {
    let count = 0
    for (const item of this.notifications) {
      if (!item.viewed) ++count
    }
    this.$store.commit('setHasNotifications', count)
  },

  async setAllViewed() {
    // const ids = []
    for (const item of this.notifications) {
      item.viewed = 1
      // ids.push(item.id)
    }
    await Http.post('/notifications/set-viewed-state', { all: 1, viewed: 1 })
    this.$store.commit('setHasNotifications', 0)
    // Http.post('/notifications/set-viewed-state', { ids, viewed: 1 })
  },

  getText(item) {
    if (item.type === 'new_response') return 'Вам поступил новый ответ по запросу'
    if (item.type === 'new_request') return 'Вам поступил новый запрос'
    if (item.type === 'request_to_archive') return 'Запрос перемещен в архив'
    if (item.type === 'request_from_archive') return 'Запрос восстановлен из архива'
    if (item.type === 'request_has_rezerv') return 'Автомобиль зарезервирован в другом автосалоне'
    return item.type
  }
}
