// import _ from 'lodash'
import store from '@/store'

export default new class Auth {
  private _user: any = null

  constructor() {
    this.initializeUser()
  }

  user(): any {
    return this._user ? this._user : this.initializeUser()
  }

  check(): boolean {
    return !!this.user()
  }

  private initializeUser(): any {
    const user = store.getters['auth/user']
    if (user) {
      user.hasRole = function (role) {
        return store.getters['auth/userRole'] === role
      }
    }
    this._user = user

    return user
  }
}

// class AuthClass {
//   private _user: any = null
//
//   constructor() {
//     this.initializeUser()
//   }
//
//   user(): any {
//     return this._user ?? this.initializeUser()
//   }
//
//   check(): boolean {
//     return !!this.user()
//   }
//
//   private initializeUser(): any {
//     const user = store.getters['auth/user']
//     if (user) {
//       user.hasRole = function (role) {
//         return store.getters['auth/userRole'] === role
//       }
//     }
//     this._user = user
//
//     return user
//   }
// }
//
// export const Auth = new AuthClass()
