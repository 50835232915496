// import Vue from 'vue'
import { io } from 'socket.io-client'
import store from '../store'
import NotificationCommon from '../views/components/NotificationCommon'

export default {

  socket: null,

  install(Vue, options) {
    store.watch(
      () => store.state.auth.authenticated,
      (authenticated) => {
        if (authenticated) this.connect(Vue)
        if (!authenticated && this.socket) this.socket.emit('logout')
      })
    Vue.prototype.$Websocket = this
  },

  connect(Vue) {
    const query = {
      user_id: store.state.auth.user.id,
      token: store.state.auth.token
    }
    if (!this.socket) {
      let url = 'http://188.225.11.172:3002'
      if (process.env.NODE_ENV !== 'development') {
        url = location.host === 'poiskpl.ru' ? 'https://poiskpl.ru:3001' : 'https://dev.poiskpl.ru:3002'
      }

      this.socket = io(url, { query })
      Vue.prototype.$socket = this.socket
      this.init(Vue)
    } else {
      this.socket.emit('login')
    }
  },

  init(Vue) {
    let pulseTitleInterval = null
    const originalTitle = document.title

    function pulseTitle() {
      if (pulseTitleInterval) {
        clearInterval(pulseTitleInterval)
        document.title = originalTitle
        return
      }
      const newTitle = 'Новое сообщение'
      document.title = newTitle
      pulseTitleInterval = setInterval(() => {
        if (document.title === newTitle) document.title = originalTitle
        else document.title = newTitle
      }, 700)
    }

    function visibilityListener() {
      // console.log('visible changed ', Vue.prototype.$isDocumentVisible())
      if (Vue.prototype.$isDocumentVisible()) {
        if (pulseTitleInterval) {
          clearInterval(pulseTitleInterval)
          document.title = originalTitle
        }
      }
    }

    Vue.prototype.$listenDocumentVisibility(visibilityListener)

    this.socket.on('new_message', data => {
      let text = '<div>Новое сообщение в чате'
      if (data.chat_id) {
        text += `<br><a href="/chat/${data.chat_id}">Перейти в чат</a></div>`
      }
      Vue.prototype.$notify({
        group: 'chat',
        text: text,
        type: 'success-request',
        duration: 5000
      })
      if (!Vue.prototype.$isDocumentVisible()) {
        pulseTitle()
      }
    })

    this.socket.on('notification', data => {
      store.commit('setHasNotifications', ++store.state.hasNotifications)
      const text = '<div>' + this.getText(data) + '</div>'
      /*
      if (data.type === 'new_response') text = '<div>Поступил новый ответ по заявке</div>'
      if (data.type === 'new_request') text = '<div>Поступил новый запрос</div>'
      if (data.type === 'request_to_archive') text = '<div>Запрос перемещен в архив</div>'
      if (data.type === 'request_from_archive') text = '<div>Запрос восстановлен из архива</div>'
      */

      Vue.prototype.$notify({
        group: 'notifications',
        text: text,
        icon: 'mdi-bell-outline',
        type: 'success-request',
        duration: 5000
      })
    })
  },

  ...NotificationCommon
}
