// import Http from '@/apis/Http'
// import axios from 'axios'

export default {
  async getCookie() {
    /* axios.get('/sanctum/csrf-cookie', {
      baseURL: process.env.VUE_APP_BACKEND_URL,
      withCredentials: true
    }) */
  }
}
